import React, {useEffect, useRef, useState} from 'react';
import {useFlags} from 'launchdarkly-react-client-sdk';
import styled from '@emotion/styled';
import {formatMessage} from 'src/components/MaintenancePage/DynamicMaintenancePageUtils';
import Chevron from 'src/svgs/Chevron';
import {localStorageService} from 'src/services/localStorageService';
import {isBannerExpanded} from 'src/constants/storageKeys';

const StyledBanner = styled.div`
  background-color: #ffe0e0;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.3s ease;
  width: 100%;
  max-height: ${(props: {isExpanded: boolean}) => (props.isExpanded ? 'auto' : '46px')};
`;

const BannerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`;

const StyledChevron = styled(Chevron)`
  transition: transform 0.3s ease;
`;

const BannerContent = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  opacity: ${(props: {isExpanded: boolean}) => (props.isExpanded ? 1 : 0)};
  max-height: ${(props: {isExpanded: boolean}) => (props.isExpanded ? '200px' : 0)};
  overflow: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease;
  width: 100%;
`;

const AnnouncementBanner: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(localStorage.getItem(isBannerExpanded) === 'true');
  const {announcementBanner} = useFlags();
  const prevFlagValue = useRef(announcementBanner);
  const title = announcementBanner?.messages?.[0]?.title || 'Scheduled downtime';

  const handleToggleCollapse = () => {
    localStorageService.setItem(isBannerExpanded, !isExpanded);
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (prevFlagValue.current !== announcementBanner) {
      setIsExpanded(true);
      prevFlagValue.current = announcementBanner;
    }
  }, [announcementBanner]);

  return (
    <StyledBanner isExpanded={Boolean(isExpanded)}>
      <BannerTitle onClick={handleToggleCollapse}>
        <span>{formatMessage(title, announcementBanner?.messages?.[0]?.parameters)}</span>
        <StyledChevron transform={isExpanded ? 180 : 0} />
      </BannerTitle>
      <BannerContent isExpanded={Boolean(isExpanded)}>
        <span>
          {' '}
          {formatMessage(announcementBanner?.messages?.[0]?.body, announcementBanner?.messages?.[0]?.parameters)}
        </span>
      </BannerContent>
    </StyledBanner>
  );
};

export default AnnouncementBanner;
